@import "../helpers/mixins/typography";
html,
body,
button,
input,
table,
td,
th {
  font-family: $helvetica-regular;
}

html,
body,
div,
h1,
h2,
h3,
h4,
h5,
h6,
article,
aside,
footer,
header,
hgroup,
nav,
section {
  margin: 0;
  padding: 0;
  vertical-align: top;
}

html {
  font-size: 62.5%;
  overflow-y: auto;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  background-color: $grey-3;
  height: 100%;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

body {
  display: block;
  font-size: 160%;
  background: #17BCBE;
  color: $text-colour;
  line-height: 1.5;
  font-weight: 400;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

ol,
ul,
nav ol,
nav ul {
  list-style: disc;
}

fieldset {
  border: none;
  padding: 0;
}

b,
strong {
  font-weight: 600;
}

img {
  border: 0;
}

abbr[title] {
  cursor: help;
}

// Set focus styles
// a {
//   -webkit-tap-highlight-color: rgba(0, 0, 0, 0.3);
// }


// a.logo:focus {
//   background-color: none;
//   outline: 0;
// }

button:focus {
  box-shadow: none;
  border: 0;
}
.nomargintop{
  margin-top: 0!important;
}
.nomarginbottom {
  margin-bottom: 0 !important;
}
.mt25 {
  margin-top: 25px;
}
.mb25 {
  margin-bottom: 25px;
}
.mr15 {
  margin-right: 15px;
}
.tabWrapper {
  ul {
    li {
      a {
        font-size: 14px;
          @include media(tablet) {
              font-size: 14px;
          }
      }
    }
  }
}
.tabsMessage {
  @include core-19();
}
.relative{
  position: relative;
}

.changeCaseOfTitle{
  .heading-xlarge{
    text-transform: inherit !important;
    background-color: #17BCBE;
    padding: 28px 8px;
    padding-left: 18px;
    font-size: 20px;
    font-weight: 300;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    color: #fff;
  }
}

.search-icon-align{
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.1875;
  box-sizing: border-box;
  border: 2px solid rgba(0, 0, 0, 0);
  border-radius: 0;
  color: #fff;
  text-align: center;
  vertical-align: top;
  cursor: pointer;
  -webkit-appearance: none;
  background-color: #000;
  border-color: #000;
  position: absolute;
  top: 0px;
  right: -5.5px;
  width: 82px;
  height: 39px;
  cursor: pointer;
}

.plan-notification{
  margin-top: 8px;
  font-size: 16px;
  img{
    width: 81%;
    float: right;
    margin-top: 9px;
  }
}

label{
  font-family: $helvetica-regular;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-weight: bold;
}
.page-title{
  font-size: 30px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-weight: bold;
}
.course-title{
  font-size: 19px;
  font-weight: 400;
   @include sentence-case();
}

.header.sub-menu{
  border: 0px!important;
  display: block;
  float: right;
  margin-bottom: 12px;
  margin-right: 15px;
  margin-top: 10px;
  @include media(mobile) {
    width: 100%;
    margin-right: 0;
  }
  .navigation-item{
      padding: 2.5px 10px;
      font-size: 14px;
      @include media(mobile) {
        width: 27%;
      }
  }
}
.page-title{
  font-size: 30px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-weight: bold;
}
.course-title{
  font-size: 36px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-weight: bold;
  @include sentence-case();
}
.width100{
  width: 100%;
}
.text-right{
  text-align: right;
}

.tag {
  display: inline-block;
  outline: 2px solid transparent;
  outline-offset: -2px;
  background-color: #dee0e2;
  padding: 5px 8px 4px;
  margin-bottom: 5px;
}

.status-tag {
  display: inline-block !important;
  outline: 2px solid transparent;
  outline-offset: -2px;
  color: #fff;
  background-color: $black;
  letter-spacing: 1px;
  text-decoration: none;
  text-transform: uppercase;
  line-height: 1;
  padding: 5px 8px 0px;
  @include bold-16();
  margin: 2px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-weight: bold;
  width: auto;
  text-align: center;

  &.status-expired {
    background-color: $grey-1;
  }
  &.green {
    background-color: $green;
  }
  &.blue {
    background-color: #1d70b8;
  }
}

.panel-wrapper {
  color: $white;
  background-color: $green;
  padding: 35px;
  text-align: center;
}

.tag-list {
  margin: 10px 0px;
  .tag {
    margin-right: 10px;
  }
}

.close-icon-align {
  position: absolute;
  top: 20px;
  right: 0px;
  width: 20px;
  height: 20px;
  display: block;
  cursor: pointer;
}

.modal-open {
  overflow: hidden;
}
.left {
 float: left;
}

.right {
  float: right;
}

.clear {
  clear: both;
}
.fade-in{
  display: none;
}
//style for content image-viewer
#image-viewer{
  .img-container {
    border: 1px solid #ccc;
    background: #ddd!important;
    min-height: 300px;
    #ngx-fs-btn{
      display: none;
    }
    button{
      background-color: rgba(255, 255, 255, 0);
      border-color: #0b0c0c;
      opacity: 1;
      &:nth-of-type(1):not(#ngx-fs-btn){
        top: 165px;       
      }
      &:nth-of-type(2):not(#ngx-fs-btn){
        top: 115px;
      }
      &:nth-of-type(3):not(#ngx-fs-btn){
        top: 65px;
      }
      &:nth-of-type(4):not(#ngx-fs-btn){
        top: 15px;
      }
      &:hover{
        background-color: rgba(255, 255, 255, 0);
      }
      &:focus{
        outline: 0;
        border: 2px solid #0b0c0c;
        box-shadow: 0 0 1px 4px #ffdd00;
      }
    }
  }
}
.changeCaseOfTitle{
  .heading-xlarge{
    text-transform: inherit!important;
  }
}
.doNotChangeCaseOfTitle {
  .heading-xlarge {
    text-transform: none;
    &:first-letter {
      text-transform: none;
    }
  }
}
#induction-questionnaire{
  legend{
    font-family: Roboto, "Helvetica Neue", sans-serif;
    font-weight: bold;
  }
}
/*dark mode*/
.logo-icon{
  display: none;
}
.dark-mode-button{
  position: absolute;
  right: 12px;
  top: 0px;
  margin-top: 12px;
}
.dark-mode{
  background: #121212;
  color: #fff!important;
  .logo-icon{
    display: block;
  }
  .notice-board-banner{
     .mbanner-message{
       .heading-m {
        color: #fff!important;
      }
    }
  }
  .pathway-label{
    color: #000000!important;
  }
  .pathway-label-behaviour-and-attitudes{
    .pathway-label{
      color: #ffffff!important;
    } 
  }
  .entry-pathway-label{
    color: #121212!important;
  }
  .together-mode-modal-background div.modal-container .together-mode-modal {
    background-color: #28282B!important;
    .top-panel{
      background-color: #666!important;
    }
  }
  .modal-dialog {
    background-color: #28282B!important;
    .heading-large p{
      color: #fff;
    }
  }
  .entry-panel{
    background-color: rgba(255, 255, 255, 0.1)!important;
  }
  .goals-alert-heading, .banner__message, .moj-banner-message, .errorMessagesdiv input[type=checkbox] + .errorMessages p{
    color: #fff!important;
  }
  .goals-alert-container{
    background-color: rgba(255, 255, 255, 0.1)!important;
  }
  .heart-favourite-false{
    filter: brightness(0) invert(1);
  }
  .status-tag{
    background-color: rgba(255, 255, 255, 0.1)!important;
  }
  .upload-section{
    #id-file{
      color: #999!important;
    }
  }
  .link-back{
    color: #fff!important;
    text-decoration-color: none!important;
    border-bottom: 1px solid #fff;
    &::before{
      border-right-color: #fff!important;
    }
  }
  .tabWrapper {
    ul {
      li{
        background-color: rgba(255, 255, 255, 0.1)!important;
        a{
          color: #fff!important;
        }
      }
      li.active{
        background: #121212!important;
      }
    }
  }
  .back-nav-content-container .nav-content-container .nav-container nav.side-navigation ul.side-navigation__list li.side-navigation__item a.side-navigation__item-active{
    background-color: rgba(255, 255, 255, 0.1)!important;
  }
  .header{
      .header-logotype{
        .logo-icon{
          display: block;
          position: absolute;
          height: 41px!important;
          
        }
          img.logo-name{
              filter: brightness(0) invert(1);
          }
      }
      .header-navigation-container-background{
        background-color: rgba(255, 255, 255, 0.1);
      }
  }
  .footer{
      .footer-logotype{
          img{
              filter: brightness(0) invert(1);
          }
      }
  }
  .header, .main-content, .footer{
      background: #121212;
  }
  .form-label, .form-label-bold, .form-label-b19{
      color: #fff;
  }
  .govuk-error-summary{
      color: #fff!important;
  }
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn {
    color: #fff!important;
    background-color: #121212;
    margin-bottom: 0px !important;
    padding-bottom: 20px !important;
  }
  .sv_main .sv_container .sv_body .sv_nav {
    background: #121212;
  }
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn .sv_q_title {
    color: #fff!important;
  }
  .sv_qcbc.sv_qcbx .sv_q_checkbox .sv_q_checkbox_label input[type=checkbox] + .checkbox-material {
    border: 2px solid #fff;
  }
  #surveyElement .sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_radiogroup .sv_q_radiogroup_label input[type=radio] + .circle {
    border: 2px solid white;
  }
  #surveyElement .sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_radiogroup.checked .sv_q_radiogroup_label input[type=radio] + .circle .sv-hidden {
    filter: brightness(0) invert(1);
  }
  .sv_main.sv_default_css .sv_q_rating_item.active .sv_q_rating_item_text {
    background: white !important;
    color: #121212 !important;
  }
  sv_q_radiogroup .sv-q-col-1 checked {
    background: white !important;
  }
  .sv_main.sv_default_css .sv_q_rating_item .sv_q_rating_item_text {
    color: white !important;
  }
  .sv_main.sv_default_css .sv_header,.sv_main.sv_default_css .sv_body {
    background: #121212;
  }
  .status-spacer, .entry-pathway-label {
    border: 3px solid #1a1a1a !important;
  }
  .entry-subtitle ,.entry-induction-content{
    color: rgb(80, 90, 95) !important;
  }
  .slider-track {
    background: rgba(250, 248, 248, 0.42);
  }
  .upper-container .months-container .previous-month {
    border-left: 5px solid transparent !important;
  }
  .sv_qcbc.sv_qcbx div.sv_q_checkbox.checked .sv_q_checkbox_label input[type=checkbox] + .checkbox-material {
    border: 2px solid #fff;
  }
  .status-spacer {
    background: #1a1a1a !important;
  }
  #surveyElement .sv_main .sv_container .sv_body .sv_p_root .sv_q_text_root {
    background: white;
    color: #121212;
  }
  .display-panel{
    background: #121212!important;
  }
  .moj-side-navigation__list{
    li{
      &:hover{
        color: #121212!important;
      }
    }
    li.active{
      color: #121212!important;
    }
  }
  .message-item.message-item--received{
    color: #121212!important;
  }
  .form-hint{
    color: #9FA5A8;
  }
  a:link, .favourite-link, .link{
    color: #69ADE8!important;
  }
  .today-label-container {
    .today-label{
      color: #E892BA!important;
    } 
  }
  .pathway-timeline.pathway-timeline-planning-for-learning{
    background-color:  rgba(251,210,189) !important;
  }
  .pathway-timeline.pathway-timeline-lesson-activities{
    background-color:  rgba(184,224,221) !important;
  }
  .pathway-timeline.pathway-timeline-checking-of-learning {
    background-color:  rgba(199,220,238) !important;
  }
  .pathway-timeline.pathway-timeline-classroom-and-behaviour-management {
    background-color:  rgba(251,221,234) !important;
  }
  .pathway-timeline.pathway-timeline-equality-diversity-and-inclusion {
    background-color:  rgba(215,221,196) !important;
  }
  .pathway-timeline.pathway-timeline-embedding-english-and-maths {
    background-color:  rgba(208,209,229) !important;
  }
  .pathway-timeline.pathway-timeline-tutorials {
    background-color:  rgba(219,185,216) !important;
  }
  .pathway-timeline.pathway-timeline-online-blended-and-mirrored-learning {
    background-color:  rgb(238, 153, 231) !important;
  }
  .pathway-timeline.pathway-timeline-personal-wellbeing-as-a-teacher {
    background-color:  rgb(241, 226, 135) !important;
  }
  .form-control#id-selector, .form-control#educationTypes ,select#id-education{
    color: #fff!important;
    background-color: #121212 !important;
    border: 2px solid #fff !important;
  }
  #surveyElement .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_select_wrapper .sv_q_dropdown_control {
    color: #fff!important;
    background-color: #121212 !important;
    border: 2px solid #fff !important;
  }
  .sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_select_wrapper select {
    color: #fff!important;
  }
  .header-logotype img{
    background-color: #fff !important;
  }
}
.rw-conversation-container {
  .rw-response{
    ul{
      height: auto;
      li{
        margin-left: 0;
      }
    }
  }
}

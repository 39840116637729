.footer {
  background: #17BCBE;
  padding-top: $gutter * 0.6;
  padding-bottom: $gutter * 0.05;
    border-top: 1px solid $grey-2;

  .footer-copyright {
    margin-top: 10px;
    line-height: 1;
    color: #ffffff;
    &:focus {
      outline: 3px solid #ffdd00;
    }
    @include media(mobile){
      text-align: center;
    }
  }
.footer-logo{
  margin: auto;
  @include media(mobile){
    text-align: center;
  }
}
  .footer-description {
    font-size: 14px;
  }

  .footer-logotype {
      margin-right: auto;
     margin-top: -3px;
    &:focus {
      outline: 3px solid #ffdd00;
    }
    @include media(tablet) {
      float: right;
      vertical-align: middle;
    }
    img {
      // height: 50px;
      margin-right: auto;
    }
    
  }
}


main {
  display: block;
  background: #fafafa;
  margin: 0;
  min-height: 100%;

  @include media(tablet) {
    min-height: 100%;
  }
  @include media(mobile) {
    min-height: 100%;
  }
}

.breadcrumbs {
  display: none;
  color: $black;

  @include media(tablet) {
    display: block;
    margin: 0;
    margin-top: 0;
    margin-bottom: -$gutter * 0.67;
    font-size: 14px;

    .breadcrumbs-list {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }

    .breadcrumbs-link {
      text-decoration: none;
      margin: 0;
      padding: 0;
      list-style-type: none;
    }

    .breadcrumbs-list-item {
      display: inline-block;
      position: relative;
      margin-left: $gutter / 3;
      padding-left: $gutter / 4;
      margin-bottom: $gutter / 6;

      &:before {
        content: "/";
        color: $black;
        display: block;
        position: absolute;
        top: 3px;
        left: -4px;
        margin: auto 0;
      }

      &:first-child {
        margin-left: 0;
        padding-left: 0;

        &:before {
          display: none;
        }
      }
    }
  }
}
.modal{
  display: block;
  height: 100vh;
  background: rgba(0,0,0, 0.7);
  position: absolute;
  top: 0;
  z-index: 9;
  width: 100%;
  overflow: hidden;
}
.modal-dialog{
  margin: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 24px;
  min-width: 400px;
  max-width: 400px;
  border-radius: 2px;
  button{
    margin-left: 0px;
  }
  .heading-large{
    margin-top: 0;
    margin: 0 0 16px;
  }
  p {
    font-size: 19px;
  }
}
.sign-up-page{
  .error-summary{
      margin-top: 0;
  }
}
#id-section-help-initialPassword {
  width: 50%;
}
#content-activity-popup{
  .form-group{
    .form-control-1-16{
      label{
        font-size: 19px;
    }
    }
    .form-control-1-10{
      label{
        font-size: 19px;
      }
    }
    .form-label-b19{
      font-size: 24px;
      font-family: Roboto, "Helvetica Neue", sans-serif;
      font-weight: bold;
    }
  }
  #id-endDate{
    .form-group{
      margin-bottom: 5px;
    }
  }
}
#generate_cv {
  .inlineRadioButtons {
    display: inherit;
  }
}
#myCvUpload {
  .inlineRadioButtons {
    display: block;
    margin-bottom: 30px;
  }
}

#planPathwayDescription{
  .selectOptions{
    button.column-fifth{
      height: 5em!important;
    }
  }
} 
/*for track based on age*/
.track-young{
  display: none;
}
.showTrack{
  display: none;
}
.track-adult{
  display: inline-block;
}
.lowerAge {
  .track-young{
    display: inline-block;
  }
  .track-adult{
    display: none!important;
  }
  .goals-alert-container{
    background: none!important;
    border: 12px solid #F7E113!important;
    border-radius: 12px;
    overflow: auto;
    margin-bottom: 40px!important;
    .showTrack{
      display: block!important;
      text-align: center;
      padding-top: 50px;
    }
    .width20{
      width: 20%;
      padding-left: 3%;
      padding-right: 3%;
      float: left;
      img{
        width: 60%;
      }
    }
    .goals-container{
      float: left;
      width: 48%;
      .goals-alert-heading{
        text-align: center;
        font-size: 36px;
        margin-top: 20px;
      }
    }
  }
  #tabWrapper.tabWrapper { 
    ul {
      height: auto!important;
      border-color: transparent!important;
      text-align: center;
      border-bottom: none!important;
      li{
        float: none!important;
        background-color: #fff!important;
        height: auto!important;
        padding: 14px 19px 8px 19px!important;
        a{
          text-decoration: none!important;
          img{
            width: 60px;
            display: block!important;
            text-align: center;
            margin: auto;
            margin-bottom: 8px;
          }
        }
      }
      li.active{
        background-color: #F3F2F1!important;
        border-color: transparent!important;
        border-bottom: 4px solid #3770B3!important;
        border-radius: 12px 12px 0 0;
        margin-top: 0!important;
        padding: 14px 19px 8px 19px!important;
      }
    }
  }
  .plan-new-wrapper{
    background-color: #85D5D9;
    border-color: transparent!important;
    border-radius: 12px;
    margin-top: -6px;
    .gantt-container{
      max-width: 917px!important;
    }
    .upper-container{
      .months-container{
        .previous-month{
          border-left-color: transparent!important;
        }
        //color: #fff!important;
      }
    } 
    .pathway-container{
      .pathway-timeline-planning-for-learning{
        background: rgba(244, 119, 56, 1);
      }
      .pathway-timeline-lesson-activities{
        background: rgba(40, 161, 151, 1);
      }
      .pathway-timeline-checking-of-learning{
        background: rgba(86, 148, 202, 1);
      }
      .pathway-timeline-classroom-and-behaviour-management{
        background: rgba(243, 153, 190, 1);
      }
      .pathway-timeline-equality-diversity-and-inclusion{
        background: rgba(133, 153, 75, 1);
      }
      .pathway-timeline-embedding-english-and-maths{
        background: rgba(111, 114, 175, 1);
      }
      .pathway-timeline-tutorials{
        background: rgba(145, 43, 136, 1);
      }
      .pathway-timeline-online-blended-and-mirrored-learning{
        background-color:  rgb(238, 153, 231) !important;
      }
      .pathway-timeline-personal-wellbeing-as-a-Teacher {
        background-color:  rgb(241, 226, 135) !important;
      }
      .timeline-decorator-container{
        background: rgba(255, 255, 255, 0.6);
      }
      .pathway-label-container{
        display: block!important;
        position: relative;
        border-radius: 10px 0 0 10px;
        .centre_aligned{
          width: 100%;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
        img{
          display: block;
          margin: auto;
          margin-bottom: 3px;
        }
      }
      .pathway-timeline{
        width: calc(100% - 134px)!important;
      }
      .triangle-right {
        width: 0;
        height: auto;
        border-top: 43px solid transparent;
        border-left: 50px solid #555;
        border-bottom: 43px solid transparent;
        position: relative;
      }
    }
    // .entry-label-content{
    //   color: #fff!important;
    // }
    .key-information-panel{
      border: 4px solid #FFDD00;
      border-radius: 8px;
      position: relative;
      padding: 20px 10px!important;
      margin-bottom: 80px!important;
      .quarter-section{
        position: absolute;
        width: 100%!important;
        left: 0;
        text-align: center;
        bottom: -65px;
        img{
          margin: auto;
        }
      }
    }
  }
  .entry-panel{
    border-radius: 15px;
    padding: 30px 40px 30px!important;
    position: relative;
  }
  app-entry-comment{
    .entry-panel{
      background: #AF4AF7!important;
      color: #fff!important;
      padding: 25px 40px 25px!important;
      .triangle-left {
        width: 0;
        height: auto;
        border-top: 20px solid transparent;
        border-right: 30px solid #AF4AF7;
        border-bottom: 20px solid transparent;
        position: absolute;
        left: -25px;
        bottom: 25px;
      }
    }
  }
  app-entry-appointment, app-entry-system, app-entry-intervention, app-entry-induction{
    .entry-panel{
      background: #ffffff!important;
    }
  }
  app-entry-intervention{
    .customPad{
      padding: 30px 40px 80px!important;
    }
  }
  .f-structure-entry-container{
    .f-line{
      background-color: transparent!important;
    }
    .f-line-container{
      margin-right: 0!important;
      .f-line-icon-container{
        background-color: #EC2589!important;
        border:transparent!important;
        border-radius: 10px!important;
        width: 65px!important;
        height: 57px!important;
        margin-top: 10px;
        z-index: 9;
      }
    }
    .entry-panel-container{
      margin-top: -20px;
      margin-left: -25px;
      .entry-title{
        background: #EC2589;
        color: #fff;
        display: inline-block;
        padding: 14px 15px 7px 15px;
        margin-left: -21px;
        border-radius: 10px;
        margin-bottom: 5px;
      }
    }
    .today-line-panel-container{
      .today-line-divider{
       background-color: #EC2589!important;
     }
   }
  }
  .left-container{
    position: relative!important;
    .image-div{
        position: absolute;
        right: 0;
        top: 18px;
    }
  }
  .right-container{
    position: absolute;
    left: 0;
    bottom: -67px;
    width: 100%!important;
    text-align: center;
    display: block!important;
    span{
      margin: auto;
      vertical-align: top;
    }
    .track-ml20{
      margin-left: 20px;
    }
    .status-spacer{
      background-color: transparent;
      border: none;
    }
  }
  .image-system-div{
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(-33%, -50%);
  } 
  .track-tab-container{
    .entry-pathway-label{
      border-radius: 10px 0 0 10px;
      height: 30px;
    }
    .small-triangle-right{
      width: 0;
      height: auto;
      border-top: 19px solid transparent;
      border-left: 30px solid #555;
      border-bottom: 19px solid transparent;
    }
  }
  .icon_align{
    margin-right: 7px;
    margin-left: 7px;
  }
  .load-more-container{
    padding-top: 60px!important;
    a{
      margin-left: 100px!important;
    }
  }
}
@media screen and (max-width: 480px){
  .lowerAge {
    .goals-alert-container {
      .width20{
        display: none!important;
      }
      .goals-container{
        width: 100%!important;
      }
    }
    #tabWrapper.tabWrapper{
      ul {
        overflow: hidden!important;
        li {
          width: calc(100% - 30px)!important;
          a{
            img{
              display: inline-block!important;
              width: 26px!important;
            }
          }
        }
      }
    }
    .plan-new-wrapper{
      margin-top: 10px!important;
      .pathway-container{
        .pathway-label-container{
          width: 26px!important;
        }
        .pathway-timeline{
          width: calc(100% - 32px) !important;
        }
        .triangle-right{
          display: none!important;
        }
      }
    } 
    .image-div, .image-system-div{
      display: none!important;
    }
    .f-structure-entry-container{
      .entry-panel-container{
        margin-left: 0!important;
      }
    }
    .entry-pathway-label{
      height: 35px!important;
      p{
        font-size: 12px!important;
      }
    }
  }
}
.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.hiddenForAT {
  visibility: hidden;
}
/*style for chatbot-rasa*/
.rw-conversation-container {
  .rw-header{
    background-color: #000000!important;
    .rw-avatar{
      left: 8px!important;
    }
  }
  .rw-messages-container{
    background: #F7E15C;
    .rw-message {
      .rw-client{
        background-color: #fff!important;
        border: 2px solid #000;
        color: #000!important;
        border-radius: 15px 15px 0px 15px!important;
      }
    }
  }
  .rw-response{
    background-color: #fff!important;
    border: 2px solid #000;
    color: #000!important;
    border-radius: 15px 15px 15px 0px!important;
    max-width: 75%!important;
    ul{
      height: auto;
      li{
        margin-left: 0;
      }
    }
  }
  .rw-avatar{
    width: 40px!important;
    height: 40px!important;
  }
}
.rw-launcher{
  background-color: transparent!important;
  width: 80px!important;
  height: 80px!important;
  background-image: url(../../assets/images/chatbot-icon-55.png);
  background-repeat: no-repeat;
  display: block;
  background-position: center;
  .rw-open-launcher, .rw-close-launcher {
    display: none;
  }
}
.rw-sender .rw-send .rw-send-icon-ready{fill: #E8BC60!important;}
.removeChatbot{
  #rasaWebchatPro{
    display: none;
  }
}
.disable {
  #header-logo{
  pointer-events: none;
  }
}
.rw-group-message.rw-from-response span.rw-message-date, .rw-group-message.rw-from-client span.rw-message-date{
  color: #000!important;
}
.rw-conversation-container {
  background: #F7E15C;
  .rw-sender{
    border-radius: 40px;
    background-color: #fff;
    border: 2px solid #000;
    margin: 15px;
    padding: 5px 5px 5px 20px!important;
    .rw-send{
      background-image: url(../../assets/images/right-arrow.png);
      background-repeat: no-repeat;
      display: block;
      background-position: center;
      width: 60px;
      height: 50px;
      border-radius: 20px;
      svg{
        display: none!important;
      }
    }
    textarea{
      border: none;
      box-shadow: none;
      border-bottom: 2px solid #333;
      margin-left: 2px!important;
      &:focus{
        border: none;
        box-shadow: none;
      }
    }
  }
  .rw-close-button{
    display: block!important;
    background-color: #fff!important;
    width: 45px!important;
    height: 45px!important;
    border-radius: 25px;
    padding: 0!important;
    &::after{
      content: 'x';
      font-size: 35px;
      display: block;
      margin-top: -8px!important;
    }
    img{
      display: none!important;
    }
  }
}
.mat-paginator-range-label {
  margin: 0 0px 0 18px !important;
}

.mat-paginator-range-label {
  margin: 0px !important;
}

//Changes for angular material style
.mat-card {
  margin-bottom: 24px;
  padding: 24px;
  .mat-card-content{
    margin-top: 20px;
  }
  .mobile-label {
    font-size: 14px;
    color: #17BCBE;
    font-weight: 600;
  }
  .spacer {
    flex: 1 1 auto;
  }
}

.mobile-label {
  display: none;
}

.header .tabWrapper ul li.tabHead {
  padding: 7px 10px !important;
}

.mat-icon-button, .mat-button.mat-accent, .mat-icon-button.mat-accent,  .mat-stroked-button.mat-accent, .mat-icon-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary{
  color: #17BCBE;
}


.label-hr {
  display: flex;
  align-items: center;
  text-align: left;
  margin: 16px 0;
  color: #17BCBE;

  span {
    font-size: 20.8px;
    font-weight: 100;
    padding-right: 8px;
    color: #17BCBE;
  }

  &:after {
    content: "";
    flex: 1;
    border-bottom: 1px solid rgba(0, 0, 0, 0.24);
  }
  &:before {
    content: "";
    width: 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.24);
  }

  &:after {
    margin-left: 0.25em;
  }
}
.card-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.card-column {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: calc(50% - 12px);
  .mat-form-field{
    width: 100%;
  }
}
.mat-card {
  margin-bottom: 24px !important;
  padding: 24px !important;
}
.mat-card .mat-card-content p {
  margin: 12px 0 ;
  font-size: 15px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.87);
}
.full-width{
  width: 100%;
  border-radius: 0 !important;
  margin-bottom: 10px;
}
.right{
  margin-left: 10px !important;
}
.mat-card .mat-card-footer {
  display: flex;
  margin: 0;
}
.mat-cell {
  font-size: 14px;
  padding-right: 12px;
  margin: 12px 0;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
}
.mat-paginator-range-label {
  margin: 0px !important;
}
.mat-header-cell {
  padding-right: 12px;
}
.section-title{
  font-size: 25px;
  color: #0000008a;
  margin: 0;
  font-weight: 400;
  margin-bottom: 16px;
}
.mat-divider{
  display: block;
  margin: 0;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: #0000001f;
  margin-bottom: 40px;
}
.no-mat-card{
  background: transparent!important;
  box-shadow: none!important;
  padding: 0!important;
  .mat-card-content{
    margin-top: 0!important;
    #content-items-recommended {
      .section-title{
        position: absolute;
        top: 0px;
      }
    }
  }
}
.mb20{
  margin-bottom: 20px;
}
.label-hr {
  display: flex;
  align-items: center;
  text-align: left;
  margin: 16px 0;
  color: #17BCBE;

  span {
    font-size: 20.8px;
    font-weight: 100;
    padding-right: 8px;
    color: #17BCBE;
  }

  &:after {
    content: "";
    flex: 1;
    border-bottom: 1px solid rgba(0, 0, 0, 0.24);
  }
  &:before {
    content: "";
    width: 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.24);
  }

  &:after {
    margin-left: 0.25em;
  }
}
.card-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.card-column {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: calc(50% - 12px);
  .mat-form-field{
    width: 100%;
  }
}
.mat-card {
  margin-bottom: 24px !important;
  padding: 24px !important;
}
.mat-card .mat-card-content p {
  margin: 12px 0;
  font-size: 16px;
  line-height: 28px;
  color: rgba(0, 0, 0, 0.87);
}
.full-width{
  width: 100%;
  border-radius: 0 !important;
  margin-bottom: 10px;
}
.right{
  margin-left: 10px !important;
}
.mat-card .mat-card-footer {
  display: flex;
  margin: 0;
}
.mat-cell {
  font-size: 14px;
  padding-right: 12px;
  margin: 12px 0;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
}
.mat-paginator-range-label {
  margin: 0px !important;
}
.mat-header-cell {
  padding-right: 12px;
}
//chatbot style
#rasaWebchatPro {
  position: fixed;
  top: 0px;
  right: 0;
  bottom: auto;
  .rw-widget-container {
    position: relative;
    top: auto;
    bottom: auto;
    padding-top: 148px;
    .rw-launcher {
      position: absolute;
      top: 60px;
    }
  }
  .rw-widget-container.rw-full-screen {
    position: fixed;
    top: 0;
    padding-top: 0;
    .rw-launcher {
      top: 63px;
      @media screen and (max-width: 749px){
        top: 58px;
      }
    }
  }
}
// breadcrumb section
.custom-bread-crumb {
  background-color: transparent !important;
  padding: 0 !important;
  margin-bottom: 0 !important;
  height: fit-content !important;
  .line {
    padding-right: 0px !important;
    margin: 0 6px !important;
  }
  .ng-star-inserted {
    font-size: 16px !important;
    color: #ffffff !important;
    align-items: center;
    word-break: break-word;
    white-space: normal;
    text-align: left;
  }
  span:nth-last-child(1) {
    font-weight: 500;
  }
  
}
//snakbar style
.mat-snack-bar-container {
  color: rgba($color: #fff, $alpha: 0.97);
  background: #17bcbe;
  .mat-simple-snackbar-action {
    color: rgba($color: #fff, $alpha: 0.97);
    background: #17bcbe;
  }
}


.track-row {
  position: relative;
}

.track-row-icon {
  margin-right: 20px;
  z-index: 1;
}

.track-row-content {
  width: calc(100% - 30px);
  margin-bottom: 20px;
  padding-top: 20px;
}

.track-row-content .badge {
  margin-bottom: 10px;
}

.track-row:before {
  content: "";
  position: absolute;
  width: 1px;
  height: 100%;
  left: 17px;
  top: 70px;
  bottom: 1px;
  background: #B1B4B6;
}

.track-row:nth-last-child(1):before {
  height: 0;
}

.track-row .track-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  border-radius: 30px;
  color: #17bcbe;
  background: #fff;
  border: 2px solid #17bcbe;
  margin-top: 36px;
}

.badge-primary {
  color: #fff;
  background-color: #61a6ff;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 16px;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.6rem;
}

.track-row-content-desc {
  padding: 15px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0 0 5px 0 rgb(0 0 0 / 10%);
}

.track-entry-content {
  font-size: 16px;
}

.check-icon {
  font-weight: bold;
}

.track-entry-title {
  font: 20px Roboto, "Helvetica Neue", sans-serif;
}

.entry-panel {
  display: flex;

  @media (max-width: 900px) {
    flex-direction: column;
  }
}

.track-right-container {
  width: auto;
  flex-direction: column;
  justify-content: space-between;
  text-align: end;
  width: fit-content;

  @media (max-width: 900px) {
    margin-top: 10px;
    text-align: start;
  }
}

.left-container {
  flex-grow: 1;
  margin-right: 15px;
  width: 80%;
}

.track-status {
  padding: 6px 8px;
  font-size: 14px;
  color: #fff;
  border-radius: 4px;
  text-align: center;
}
@use 'sass:math';

.site-width-container {
  @include site-width-container();
  @include contain-floats();
  outline: none;
  position: relative;
}

.full-width-container {
  @include outdent-to-full-width;
  @include contain-floats();
  outline: none;
}

.grid-row {
  @include grid-row;
  // background-color: #17BCBE;
  // height: 24px;
}

.column-seventh,
.column-one-seventh {
  @include grid-column(math.div(1 , 7));
}

.column-sixth,
.column-one-sixth {
  @include grid-column(math.div(1 , 6));
}
.column-fifth,
.column-one-fifth {
  @include grid-column(math.div(1 , 5));
}

.column-quarter,
.column-one-quarter {
  @include grid-column(math.div(1 , 4));
}
.column-three-quarter {
  @include grid-column(math.div(3 , 4));
}

.column-half,
.column-one-half {
  @include grid-column(math.div(1 , 2));
}

.column-third,
.column-one-third {
  @include grid-column(math.div(1 , 3));
}

.column-two-thirds {
  @include grid-column(math.div(2 , 3));
}

.column-full {
  @include grid-column(1);
}
